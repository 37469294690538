import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons/faArrowRightLong';

import { useFiberOrderContext } from '../Provider';
import LeadForm from '../LeadForm';
import MediumWidth from 'layouts/medium-width';
import Spacing from 'layouts/Spacing';
import Button from 'components/forms/Button';
import TitleAndText from 'parts/title-and-text/TitleAndText';

const MediumWidthWrap = styled(MediumWidth)`
	padding: 0;
`;

export default function Brl() {
	const { deliverResults } = useFiberOrderContext();

	// Get BRL page slug
	const brlPageSlug = getBrlPageSlug(deliverResults);

	if (deliverResults?.result !== 'brl') return null;

	// If no page-match found with base offer, then show the LeadForm component
	if (!brlPageSlug) {
		return (
			<LeadForm
				id={`fiber-by-${deliverResults?.result}`}
				title="Vi har avtale med ditt boligselskap!"
				text="Fyll ut skjemaet under, så hjelper vi deg med å finne riktig produkt."
				showLinkToDevelopmentPage={false}
			/>
		);
	}

	// Otherwise show call to action (BRL page with base offer)
	return (
		<MediumWidthWrap
			id={`fiber-by-${deliverResults?.result}`}
			data-cy={`fiber-by-${deliverResults?.result}`}>
			<Spacing spacing={{ top: 'small', bottom: 'none' }}>
				<TitleAndText
					title="Vi har avtale med ditt boligselskap."
					text={
						deliverResults?.deliverable?.brl_base_offer &&
						`Du kan velge mellom produkter i pakken ${deliverResults?.deliverable?.brl_base_offer}.`
					}
					nested={true}
				/>

				<Button
					data-cy="fiber-by-brl-cta"
					aria-label="Se produktene i pakken"
					onClick={e => {
						e.preventDefault();
						navigate(
							brlPageSlug ||
								'/tv-og-internett/borettslag-og-sameie/'
						);
					}}
					icon={faArrowRightLong}>
					Se produktene i pakken
				</Button>
			</Spacing>
		</MediumWidthWrap>
	);
}

/**
 * Get BRL page slug
 * @param {Object} deliverResults - The deliver results from the Fiber API.
 * @returns {string} - The BRL page slug.
 */
function getBrlPageSlug(deliverResults) {
	if (!deliverResults?.deliverable?.brl_base_offer) return;

	// BRL pages in Contentful
	const brlPages = [
		{ 'Altibox Flex S': '/kampanje/altibox-flex-s/' },
		{ 'Altibox Flex M': '/kampanje/altibox-flex-m/' },
		{ 'Altibox Flex L': '/kampanje/altibox-flex-l/' },
		{ 'Altibox Fiberaksess': '/kampanje/altibox-fiberaksess/' },
		{ 'Altibox Fiberbredbånd': '/kampanje/altibox-fiberbredband/' },
	];

	const brlPage = brlPages?.find(
		page => page[deliverResults?.deliverable?.brl_base_offer]
	);

	// return value without key
	const brlPageSlug = brlPage ? Object.values(brlPage)[0] : null;

	return brlPageSlug;
}
